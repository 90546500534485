//Libs
import moment from "moment";
import Immutable from "immutable";
//Utils
import GENERAL from "utils/constants/general";
import {
  GlobalUtils,
  ProjectUtils,
  ServiceUtils,
  OrderUtils,
  UserUtils,
} from "utils";
import { idbHandler } from "utils/libs";
import { formatDate } from "utils/libs/dateFormats";
import ManagePermissions from "./Authorization";
import UploadResourceUtils from "components/components/UploadResource/UploadResourceUtils";
//Keywords
import COLLECTOR_KEYWORDS from "./keywords";
//Checks
import CheckUtils from "./CheckUtils";

const { ENV } = GENERAL;
const { DUPLICATION } = COLLECTOR_KEYWORDS.COLLECTORS;
export default class CollectorUtils {
  //CHECKS
  //Check is array
  static checkArray(arr) {
    return Array.isArray(arr) ? arr : [];
  }
  //Check is collector have valid photos
  static checkCollectorPhotosValid = (collector) =>
    this.checkArray(collector.photos) && collector.photos.length > 0;
  //Check unsucess collector
  static checkSuccessCollector = (collector) =>
    collector.status === COLLECTOR_KEYWORDS.COLLECTORS.STATUS.SUCCESS;
  //Check unsucess collector
  static checkUnsuccessCollector = (collector) =>
    collector.status !== COLLECTOR_KEYWORDS.COLLECTORS.STATUS.SUCCESS;
  //Check collector lifetime
  static checkCollectorLifetime = (collector, now) => {
    const collectorCreatedAt = new Date(collector.createdAt);
    const collectorLifetimeOut = collectorCreatedAt.setHours(
      collectorCreatedAt.getHours() + 6
    );
    return (
      this.checkSuccessCollector(collector) && collectorLifetimeOut - now > 0
    );
  };
  //Check new collector values in offline collector values
  static checkNewCollectorValuesInOfflineCollectorValues(
    offlineCollectorValue,
    newCollectorValues
  ) {
    //Get new collector object
    const newCollector = newCollectorValues.find((cv) => {
      const newCollectorProps = this.getCollectorProps(
        { order_id: cv.auditOrderId, docId: cv.docId },
        cv
      );
      return (
        cv.id === offlineCollectorValue.id ||
        newCollectorProps.id === offlineCollectorValue.id
      );
    });

    const checkOfflineCollectorValueCreatedAtIsOlder = () => {
      //Get offline collector created at
      const offlineCollectorValueCreatedAt = new Date(
        offlineCollectorValue?.createdAt
      );
      //Get new collector created at
      const newCollectorCreatedAt = new Date(newCollector?.createdAt);
      /**
       * Si la <fecha de creación del offlineCollectorValue> - <la fecha de creación del newCollectorValue>
       * es superior a 0, significa que el offlineCollectorValue se creó después que el newCollectorValue,
       * por lo que seguiremos guardando el offlineCollectorValue
       */
      return offlineCollectorValueCreatedAt - newCollectorCreatedAt > 0;
    };

    /**
     * Importante
     * El siguiente return, retornará el offlineCollectorValue para seguirlo
     * manteniendo almacenado. Los demás serán eliminados.
     * El offlineCollectorValue sólo se debe retornar si:
     * 1) Si no viene una versión más nueva en newCollectorValues
     * 2) Si la fecha de creación del offlineCollectorValue es superior a
     * la fecha de creación del newCollectorValue.
     */
    return !newCollector || checkOfflineCollectorValueCreatedAtIsOlder();
  }
  //Check read-only collector
  static checkReadOnlyCollector(docId, transactionDocs, profile) {
    const tDoc = transactionDocs.find((t) => t.id === docId);
    //Return read-only if...
    if (tDoc) {
      //User not have permission
      if (!new ManagePermissions(profile).checkEditCollectors()) return true;
      //Document is closed
      if (tDoc.state === COLLECTOR_KEYWORDS.TRANSACTION_DOCS.STATE.CLOSED)
        return true;
      //EXP User is not creator
      if (
        profile?.user?.division_id === GENERAL.DIVISION_ID.EXP &&
        Number(tDoc.createdBy) !== Number(profile.user.id)
      )
        return true;
    }
  }
  //Check can edit report
  static checkEditOTDCollectors(doc, profile) {
    //Si el usuario tiene permisos para editar
    if (new ManagePermissions(profile).checkEditCollectors()) {
      //Si es un EXP, sólo puede editar sus propios reportes
      if (profile?.user?.division_id === GENERAL.DIVISION_ID.EXP) {
        return Number(doc.createdBy) === Number(profile.user.id);
      }
      //Si es un ADM, puede editar
      return profile?.user?.division_id === GENERAL.DIVISION_ID.ADM;
    }
    return false;
  }
  //Check autoSync active
  static checkAutoSyncActive(autoSyncActive, sendingCollectorValues) {
    return autoSyncActive || sendingCollectorValues;
  }
  //Check pending collector and resource
  static async checkIsCollectorAndResourceRequiredDataPending({
    isActiveDataValidator, //Data validator is active?
    validateData,
    updateCollectorRequiredValidation,
    sendToast,
  }) {
    updateCollectorRequiredValidation({
      highlight: {},
      state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.VALIDATING,
    });
    const { pendingCollectors, pendingCollectorResources } =
      await this.isPendingRequiredData(validateData);

    if (
      isActiveDataValidator &&
      (Object.keys(pendingCollectors).length > 0 ||
        Object.keys(pendingCollectorResources).length > 0)
    ) {
      sendToast({
        message: "Le he marcado los datos requeridos",
        type: "warn",
      });
      updateCollectorRequiredValidation({
        highlight: {
          ...pendingCollectors,
          ...pendingCollectorResources,
        },
        state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.REQUIRED,
      });
      updateCollectorRequiredValidation({
        state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING,
      });
      return false;
    } else {
      updateCollectorRequiredValidation({
        state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.APPROVED,
      });
      return true;
    }
  }

  //GETTERS
  //Get unsuccess collectors
  static getUnsuccessCollectors({ collectors, max = 50 }) {
    return this.checkArray(collectors)
      .filter((c) => this.checkUnsuccessCollector(c))
      .slice(0, max);
  }
  //Get unsuccess collector count from order id
  static getUnsuccessCollectorCountFromOrder(orderId, collectors) {
    return this.checkArray(collectors)
      .filter((c) => c.auditOrderId === orderId)
      .filter((c) => this.checkUnsuccessCollector(c)).length;
  }
  //Get non-removable collectors
  // static getNonRemovableCollectors(collectors) {
  //   const now = new Date();
  //   return this.checkArray(collectors).filter(
  //     (c) =>
  //       this.checkUnsuccessCollector(c) || this.checkCollectorLifetime(c, now)
  //   );
  // }
  static getNonRemovableCollectors(collectors) {
    return this.checkArray(collectors).filter((c) =>
      this.checkUnsuccessCollector(c)
    );
  }
  //Combine success collectors
  static async getLastestUpdateCollectorValues(newCollectorValues) {
    return this.reloadOfflineCollectorValues().then((offlineCollectorValues) =>
      offlineCollectorValues.filter((ocv) =>
        this.checkNewCollectorValuesInOfflineCollectorValues(
          ocv,
          newCollectorValues
        )
      )
    );
  }
  static async combineCollectorValues(newCollectorValues, { format } = {}) {
    let _newCollectorValues = Immutable.List(newCollectorValues).toJS();

    if (format === "beforeMatch")
      _newCollectorValues =
        this.getFormattedCollectorValues(_newCollectorValues);

    const latestUpdateCollectorValues =
      await this.getLastestUpdateCollectorValues(_newCollectorValues);

    //The purpose of this formatting is mainly to update the value of the "id" prop
    if (format === "afterMatch")
      _newCollectorValues = this.getFormattedCollectorValues(
        _newCollectorValues,
        ["id"]
      );

    const combinedCollectorValues = [
      ...latestUpdateCollectorValues,
      ..._newCollectorValues,
    ].filter((cv) => !cv.collectorDeleted);

    this.mutateOfflineCollectorValuesV2(combinedCollectorValues);
    return combinedCollectorValues;
  }
  //Get collector value from IDX
  static getCollectorValueFromIdx(collectorValues, idx) {
    if (CheckUtils.invalidIdx(idx)) {
      return null;
    }
    return collectorValues[idx];
  }
  //Get collector photo props
  static getCollectorPhotoProps(photo) {
    if (!photo || typeof photo !== "object") return {};
    return !photo["photoProps"] || typeof photo["photoProps"] !== "object"
      ? {}
      : photo["photoProps"];
  }
  //Get Collector IDX from Collector values
  static getCollectorIdxFromCollectorValues(collectorValues, collectorProps) {
    return this.checkArray(collectorValues).findIndex((collector) =>
      CheckUtils.collectorExistsInCollectorValues(collector, collectorProps)
    );
  }

  //Get duplicated group layout
  static getDuplicatedReview(
    newReviewId,
    newReviewName,
    { docId, orderId, review, createdAt }
  ) {
    return {
      ...review,
      docId,
      orderId,
      id: `duplicated#${newReviewId}`,
      name: newReviewName,
      createdAt,
      reviewIdx: this.getDuplicatedReviewIdx({
        orderId,
        serviceId: review.serviceId,
        serviceTaskId: review.serviceTaskId,
        duplicatedReviewName: newReviewName,
      }),
      collectors: review.collectors.map((collector) => ({
        ...collector,
        reviewId: `duplicated#${newReviewId}`,
        reviewName: newReviewName,
        layoutId: null,
        groupIdx: this.getDuplicatedGroupIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: newReviewName,
          duplicatedGroupName: collector.groupName,
        }),
        subgroupIdx: this.getDuplicatedSubgroupIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: newReviewName,
          groupId: collector.groupName,
          duplicatedSubgroupName: collector.subgroupName,
        }),
        collectorIdx: this.getDuplicatedCollectorIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: newReviewName,
          groupId: collector.groupName,
          subgroupId: collector.subgroupName,
          duplicatedCollectorName: collector.name,
        }),
        photos: collector.photos.map((photo) => ({
          ...photo,
          layoutPhotoId: null,
          photoIdx: UploadResourceUtils.getDuplicatedPhotoIdx({
            orderId,
            serviceId: review.serviceId,
            serviceTaskId: review.serviceTaskId,
            reviewId: newReviewName,
            groupId: collector.groupName,
            subgroupId: collector.subgroupName,
            collectorId: collector.name,
            duplicatedPhotoName: photo.name,
          }),
        })),
      })),
    };
  }
  //Get duplicated group layout
  static getDuplicatedGroup(
    newGroupId,
    newGroupName,
    { docId, orderId, collectors, review, createdAt }
  ) {
    return {
      ...review,
      docId,
      orderId,
      createdAt,
      reviewIdx: this.getDuplicatedReviewIdx({
        orderId,
        serviceId: review.serviceId,
        serviceTaskId: review.serviceTaskId,
        duplicatedReviewName: review.name,
      }),
      collectors: collectors.map((collector) => ({
        ...collector,
        groupId: `duplicated#${newGroupId}`,
        groupName: newGroupName,
        layoutId: null,
        createdAt,
        groupIdx: this.getDuplicatedGroupIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: review.name,
          duplicatedGroupName: newGroupName,
        }),
        subgroupIdx: this.getDuplicatedSubgroupIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: review.name,
          groupId: newGroupName,
          duplicatedSubgroupName: collector.subgroupName,
        }),
        collectorIdx: this.getDuplicatedCollectorIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: review.name,
          groupId: newGroupName,
          subgroupId: collector.subgroupName,
          duplicatedCollectorName: collector.name,
        }),
        photos: collector.photos.map((photo) => ({
          ...photo,
          layoutPhotoId: null,
          photoIdx: UploadResourceUtils.getDuplicatedPhotoIdx({
            orderId,
            serviceId: review.serviceId,
            serviceTaskId: review.serviceTaskId,
            reviewId: review.name,
            groupId: newGroupName,
            subgroupId: collector.subgroupName,
            collectorId: collector.name,
            duplicatedPhotoName: photo.name,
          }),
        })),
      })),
    };
  }
  //Get duplicated subgroup layout
  static getDuplicatedSubgroup(
    newSubgroupId,
    newSubgroupName,
    { docId, orderId, collectors, review, createdAt }
  ) {
    return {
      ...review,
      docId,
      orderId,
      createdAt,
      reviewIdx: this.getDuplicatedReviewIdx({
        orderId,
        serviceId: review.serviceId,
        serviceTaskId: review.serviceTaskId,
        duplicatedReviewName: review.name,
      }),
      collectors: collectors.map((collector) => ({
        ...collector,
        subgroupId: `duplicated#${newSubgroupId}`,
        subgroupName: newSubgroupName,
        layoutId: null,
        createdAt,
        groupIdx: this.getDuplicatedGroupIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: review.name,
          duplicatedGroupName: collector.groupName,
        }),
        subgroupIdx: this.getDuplicatedSubgroupIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: review.name,
          groupId: collector.groupName,
          duplicatedSubgroupName: newSubgroupName,
        }),
        collectorIdx: this.getDuplicatedCollectorIdx({
          orderId,
          serviceId: review.serviceId,
          serviceTaskId: review.serviceTaskId,
          reviewId: review.name,
          groupId: collector.groupName,
          subgroupId: newSubgroupName,
          duplicatedCollectorName: collector.name,
        }),
        photos: collector.photos.map((photo) => ({
          ...photo,
          layoutPhotoId: null,
          photoIdx: UploadResourceUtils.getDuplicatedPhotoIdx({
            orderId,
            serviceId: review.serviceId,
            serviceTaskId: review.serviceTaskId,
            reviewId: review.name,
            groupId: collector.groupName,
            subgroupId: newSubgroupName,
            collectorId: collector.name,
            duplicatedPhotoName: photo.name,
          }),
        })),
      })),
    };
  }
  //Get duplicated collector layout //TODO: Debe terminarse el proceso de duplicación de los collectors
  // static getDuplicatedCollector({
  //   orderId,
  //   collector,
  //   newCollectorId,
  //   newCollectorName,
  //   review,
  //   groupId,
  // }) {
  //   return {
  //     ...collector,
  //     id: `duplicated#${newCollectorId}`,
  //     collectorName: newCollectorName,
  //     layoutId: null,
  //     collectorIdx: this.getDuplicatedCollectorIdx({
  //       orderId,
  //       serviceId: review.serviceId,
  //       serviceTaskId: review.serviceTaskId,
  //       reviewId: review.id,
  //       groupId,
  //       duplicatedCollectorName: newCollectorName,
  //     }),
  //   };
  // }
  //Get duplicated photo layout
  static getDuplicatedPhoto(
    newPhotoId,
    newPhotoName,
    { docId, orderId, collector, photo, review, createdAt }
  ) {
    return {
      ...review,
      docId,
      orderId,
      createdAt,
      reviewIdx: this.getDuplicatedReviewIdx({
        orderId,
        serviceId: review.serviceId,
        serviceTaskId: review.serviceTaskId,
        duplicatedReviewName: review.name,
      }),
      collectors: [
        {
          ...collector,
          createdAt,
          groupIdx: this.getDuplicatedGroupIdx({
            orderId,
            serviceId: review.serviceId,
            serviceTaskId: review.serviceTaskId,
            reviewId: review.name,
            duplicatedGroupName: collector.groupName,
          }),
          subgroupIdx: this.getDuplicatedSubgroupIdx({
            orderId,
            serviceId: review.serviceId,
            serviceTaskId: review.serviceTaskId,
            reviewId: review.name,
            groupId: collector.groupName,
            duplicatedSubgroupName: collector.subgroupName,
          }),
          collectorIdx: this.getDuplicatedCollectorIdx({
            orderId,
            serviceId: review.serviceId,
            serviceTaskId: review.serviceTaskId,
            reviewId: review.name,
            groupId: collector.groupName,
            subgroupId: collector.subgroupName,
            duplicatedCollectorName: collector.name,
          }),
          photos: [
            {
              ...photo,
              id: `duplicated#${newPhotoId}`,
              name: newPhotoName,
              layoutPhotoId: null,
              createdAt,
              photoIdx: UploadResourceUtils.getDuplicatedPhotoIdx({
                orderId,
                serviceId: review.serviceId,
                serviceTaskId: review.serviceTaskId,
                reviewId: review.name,
                groupId: collector.groupName,
                subgroupId: collector.subgroupName,
                collectorId: collector.name,
                duplicatedPhotoName: newPhotoName,
              }),
            },
          ],
        },
      ],
    };
  }

  //Get duplicated review level Idx
  static getDuplicatedReviewIdx({
    orderId,
    serviceId,
    serviceTaskId,
    duplicatedReviewName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${duplicatedReviewName}`;
  }
  //Get duplicated group level Idx
  static getDuplicatedGroupIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    duplicatedGroupName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${duplicatedGroupName}`;
  }
  //Get duplicated group level Idx
  static getDuplicatedSubgroupIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    groupId,
    duplicatedSubgroupName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${groupId}subgroup${duplicatedSubgroupName}`;
  }
  //Get duplicated collector level Idx
  static getDuplicatedCollectorIdx({
    orderId,
    serviceId,
    serviceTaskId,
    reviewId,
    groupId,
    subgroupId,
    duplicatedCollectorName,
  }) {
    return `order${orderId}service${serviceId}serviceTask${serviceTaskId}review${reviewId}group${groupId}subgroup${subgroupId}collector${duplicatedCollectorName}`;
  }
  //Get collector level Idx
  static getCollectorIdx(
    order,
    {
      auditOrderId,
      layoutId,
      collectorId,
      id,
      subgroupId,
      groupId,
      reviewId,
      serviceTaskId,
      inventoryItemId,
    },
    { itemId }
  ) {
    const _collectorId = collectorId || id;
    const _orderId = auditOrderId || order.order_id;
    const _inventoryItemId = itemId || inventoryItemId;
    const base = `order${_orderId}layoutId${layoutId}collector${_collectorId}subgroup${subgroupId}group${groupId}review${reviewId}serviceTaskId${serviceTaskId}`;
    if (!!_inventoryItemId) return base + `inventoryItemId${_inventoryItemId}`;
    return base;
  }
  //Get collector props
  static getCollectorProps = (order = {}, collector = {}, inventory = {}) => ({
    id: this.getCollectorIdx(order, collector, inventory),
    auditOrderId: collector.auditOrderId || order.order_id,
    docId: collector.docId || order.docId,
    layoutId: collector.layoutId,
    collectorId: collector.collectorId || collector.id,
    collectorName: collector.collectorName || collector.name,
    subgroupId: collector.subgroupId,
    subgroupName: collector.subgroupName,
    groupId: collector.groupId,
    groupName: collector.groupName,
    reviewId: collector.reviewId,
    reviewName: collector.reviewName,
    serviceTaskId: collector.serviceTaskId,
    serviceId: collector.serviceId,
    templateId: collector.templateId,
    required: collector.required,
    sort: collector.sort,
    //Inventory
    inventoryItemId: inventory.itemId || collector.inventoryItemId || null,
    inventorySerieId: inventory.serieId || collector.inventorySerieId || null,
    inventoryAmount: inventory.amount || collector.inventoryAmount || null,
    inventoryInitialAmount:
      inventory.initialAmount || collector.inventoryInitialAmount || null,
    inventoryFinalAmount:
      inventory.finalAmount || collector.inventoryFinalAmount || null,
  });
  //Get formatted duplicate element
  static getFormattedDuplicateElement(
    level,
    { docId, orderId, collector, review, photo }
  ) {
    if (level === DUPLICATION.LEVELS.REVIEW) {
      return {
        docId,
        orderId,
        name: review.name, //Label to show in delete confirmation message
        reviewId: review.id,
        reviewName: review.name,
        serviceTaskId: review.serviceTaskId,
        serviceId: review.serviceId,
        createdAt: new Date().toISOString(),
        //TODO: Revisar si aquí falta añadir el templateId o no.
      };
    }
    if (level === DUPLICATION.LEVELS.GROUP) {
      return {
        docId,
        orderId,
        name: collector.groupName, //Label to show in delete confirmation message
        groupId: collector.groupId,
        groupName: collector.groupName,
        reviewId: collector.reviewId,
        reviewName: review.name,
        serviceTaskId: collector.serviceTaskId,
        serviceId: review.serviceId,
        templateId: collector.templateId,
        createdAt: new Date().toISOString(),
      };
    }
    if (level === DUPLICATION.LEVELS.SUBGROUP) {
      return {
        docId,
        orderId,
        name: collector.subgroupName, //Label to show in delete confirmation message
        subgroupId: collector.subgroupId,
        subgroupName: collector.subgroupName,
        groupId: collector.groupId,
        groupName: collector.groupName,
        reviewId: collector.reviewId,
        reviewName: review.name,
        serviceTaskId: collector.serviceTaskId,
        serviceId: review.serviceId,
        templateId: collector.templateId,
        createdAt: new Date().toISOString(),
      };
    }
    //TODO: Implement collector duplicated element
    // if (level === DUPLICATION.LEVELS.COLLECTOR) {}
    if (level === DUPLICATION.LEVELS.PHOTO) {
      return {
        docId: docId || photo.docId,
        orderId: orderId || photo.auditOrderId,
        name: photo.photoName, //Label to show in delete confirmation message
        photoId: photo.photoId,
        photoName: photo.photoName,
        subgroupId: photo.subgroupId,
        subgroupName: photo.subgroupName,
        collectorId: photo.collectorId,
        collectorName: photo.collectorName,
        groupId: photo.groupId,
        groupName: photo.groupName,
        reviewId: photo.reviewId,
        reviewName: photo.reviewName,
        serviceTaskId: photo.serviceTaskId,
        serviceId: photo.serviceId,
        createdAt: new Date().toISOString(),
      };
    }
  }
  //Get combine order and service task
  static getCombineOrderAndServiceTask(order, serviceTaskId) {
    return `order${order.order_id}serviceTaskId${serviceTaskId}`;
  }
  //Reload offline collector values
  static async reloadOfflineCollectorValues() {
    const offlineCollectorValues = await idbHandler.getCollectorValues();
    return Immutable.List(this.checkArray(offlineCollectorValues)).toJS();
  }
  //Reload offline autoFillCollector
  static reloadOfflineAutoFillCollector() {
    return idbHandler.getAutoFillCollector();
  }
  //Reload offline duplicated collector layout
  static async reloadOfflineDuplicatedCollectorLayout() {
    const duplicatedCollectorLayout =
      await idbHandler.getCollectorDuplicatedLayout();
    return Immutable.List(duplicatedCollectorLayout).toJS();
  }
  //Get reviewManage
  static async getOfflineReviewManage() {
    const [offlineCollectorValues, offlineAutoFillCollector] =
      await Promise.all([
        this.reloadOfflineCollectorValues(),
        this.reloadOfflineAutoFillCollector(),
        // this.reloadOfflineDuplicatedCollectorLayout(),
      ]);
    return {
      offlineCollectorValues,
      offlineAutoFillCollector,
      // offlineDuplicatedCollectorLayout: response[2],
    };
  }
  //Get all collectors from collectorLayout
  static getAllCollectorsFromReviews(collectorLayout) {
    return collectorLayout.reduce(
      (acc, review) =>
        Array.isArray(review.collectors) ? [...acc, ...review.collectors] : acc,
      []
    );
  }
  //Get required collector pending
  static isRequiredCollectorPending(order, collectorValues, collectors) {
    //I'm looking for a required collector that hasn't been completed in <collectorValues>
    return collectors.reduce((acc, collector) => {
      if (!collector.required) return acc;

      const collectorProps = this.getCollectorProps(order, collector);
      const collectorValueIdx = this.getCollectorIdxFromCollectorValues(
        collectorValues,
        collectorProps
      );
      const collectorValue = collectorValues[collectorValueIdx];
      if (
        collectorValue?.value &&
        collectorValue.value !==
          COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.UNDEFINED_VALUE
      )
        return acc;

      return {
        ...acc,
        ...this.setHighlightCollector(collectorProps),
      };
    }, {});
  }
  //Get highlight collector
  static getHighlightCollector(
    level,
    highlight,
    {
      id,
      subgroupId,
      subgroupName,
      groupId,
      groupName,
      reviewId,
      serviceTaskId,
    }
  ) {
    return {
      [DUPLICATION.LEVELS.COLLECTOR]: highlight[`collectorId_${id}`],
      [DUPLICATION.LEVELS.SUBGROUP]:
        highlight[
          `subgroupId_${subgroupId || subgroupName}_groupId_${
            groupId || groupName
          }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`
        ],
      [DUPLICATION.LEVELS.GROUP]:
        highlight[
          `groupId_${
            groupId || groupName
          }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`
        ],
      [DUPLICATION.LEVELS.REVIEW]:
        highlight[`reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`],
      [DUPLICATION.LEVELS.SERVICE_TASK]:
        highlight[`serviceTaskId_${serviceTaskId}`],
    }[level];
  }
  static getSuccessInventoryCollectorValues(collectorValues) {
    return collectorValues.filter(
      (cv) => !!cv.inventoryItemId && this.checkSuccessCollector(cv)
    );
  }
  //Set highlight collector
  static setHighlightCollector({
    id,
    subgroupId,
    subgroupName,
    groupId,
    groupName,
    reviewId,
    serviceTaskId,
  }) {
    return {
      [`collectorId_${id}`]: true,
      [`subgroupId_${subgroupId || subgroupName}_groupId_${
        groupId || groupName
      }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`]: true,
      [`groupId_${
        groupId || groupName
      }_reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`]: true,
      [`reviewId_${reviewId}_serviceTaskId_${serviceTaskId}`]: true,
      [`serviceTaskId_${serviceTaskId}`]: true,
    };
  }
  //Get pending required data
  static async isPendingRequiredData({
    order,
    filteredCollectorLayout,
    collectorValues,
  }) {
    const resources = await UploadResourceUtils.reloadOfflineResources().then(
      (_resources) =>
        _resources.filter((r) => r.auditOrderId === order.order_id)
    );
    const pendingCollectors = this.isRequiredCollectorPending(
      order,
      collectorValues,
      this.getAllCollectorsFromReviews(filteredCollectorLayout)
    );
    const pendingCollectorResources =
      UploadResourceUtils.isRequiredCollectorResourcePending(
        order,
        this.getAllCollectorsFromReviews(filteredCollectorLayout),
        resources
      );

    return { pendingCollectors, pendingCollectorResources };
  }
  //Get grouped collectors from review
  static getGroupedCollectorsFromReview(collectors) {
    return this.checkArray(collectors).filter((c) => c.groupId);
  }
  //Get ungrouped collectors from review
  static getUngroupedCollectorsFromReview(collectors) {
    return this.checkArray(collectors).filter((c) => !c.groupId);
  }
  //Get ungrouped collectors from group
  static getGroupedCollectorsFromGroup(collectors, groupId) {
    return this.checkArray(collectors).filter(
      (collector) => collector.groupId === groupId
    );
  }
  //Get groups from review
  static getGroupsFromGroupedCollectors(
    level,
    { docId, orderId, review },
    collectors
  ) {
    const levelId =
      level === DUPLICATION.LEVELS.GROUP ? "groupId" : "subgroupId";
    return this.checkArray(collectors)
      .filter((collector) => collector[levelId])
      .reduce((groups, collector) => {
        const group = groups.find(
          (group) => group[levelId] === collector[levelId]
        );
        if (!group)
          groups.push(
            this.getFormattedDuplicateElement(level, {
              docId,
              orderId,
              collector,
              review,
            })
          );
        return groups;
      }, []);
  }
  //Get subgrouped collectors
  static getSubgroupedCollectors(collectors) {
    return this.checkArray(collectors).filter((c) => c.subgroupId);
  }
  //Get unsubgrouped collectors from review
  static getUnsubgroupedCollectors(collectors) {
    return this.checkArray(collectors).filter((c) => !c.subgroupId);
  }
  //Get unsubgrouped collectors from group
  static getSubgroupedCollectorsFromSubgroup(collectors, subgroupId) {
    return this.checkArray(collectors).filter(
      (collector) => collector.subgroupId === subgroupId
    );
  }

  //Get default falsify collector values
  // static getDefaultFalsifyCollectorValues(order, collectorValues, collectors) {
  //   //Autocomplete with default falsify values
  //   return collectors.reduce((acc, collector) => {
  //     const collectorProps = this.getCollectorProps(order, collector);
  //     if (collector.typeKey === "boolean") {
  //       if (
  //         this.getCollectorIdxFromCollectorValues(
  //           collectorValues,
  //           collectorProps
  //         ) === -1
  //       ) {
  //         acc.push({
  //           ...collectorProps,
  //           value: false,
  //         });
  //       }
  //     }
  //     return acc;
  //   }, []);
  // }
  //Get coords from value
  static getCoordsFromValue(value) {
    if (value) {
      try {
        const coords = JSON.parse(value);
        if (coords) return coords;
      } catch (err) {
        return {};
      }
    }
    return {};
  }
  //Get service tasks
  static getServiceTasks(collectorLayout) {
    return collectorLayout.reduce((acc, review) => {
      let idx = acc.findIndex((r) => r.serviceTaskId === review.serviceTaskId);
      if (idx === -1) {
        const { serviceTaskId, serviceTaskName, serviceTaskProps } = review;
        acc.push({
          serviceTaskId,
          serviceTaskName,
          serviceTaskProps,
        });
      }
      return acc;
    }, []);
  }
  //Get template id from collector layout
  static getTemplateIdFromCollectorLayout(collector, collectorLayout) {
    if (!collector || !Array.isArray(collectorLayout)) return null;
    return collectorLayout.reduce((acc, r) => {
      const coll = r.collectors.find((c) => c.layoutId === collector.layoutId);
      if (coll) acc = coll.templateId;
      return acc;
    }, null);
  }
  //Get saved DB collector values
  static getFormattedCollectorValues(collectorValues, onlyKeys) {
    return Immutable.List(collectorValues)
      .toJS()
      .map((cv) => {
        const collectorProps = this.getCollectorProps(
          { order_id: cv.auditOrderId, docId: cv.docId },
          cv
        );
        delete cv.photos;
        if (!onlyKeys)
          return {
            ...cv,
            ...collectorProps,
          };
        for (let key of onlyKeys) {
          cv[key] = collectorProps[key];
        }
        return cv;
      });
  }
  //Get collector value
  static getCollectorValue({ value, typeKey, readOnlyCollector, profile }) {
    //Only Read Collector
    if (readOnlyCollector) {
      switch (typeKey) {
        //BOOLEAN
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.BOOLEAN:
          if (value === undefined)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === "true" || value === true)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.YES_RESPONSE;
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NO_RESPONSE;
        //STRING
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.STRING:
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return value;
        //NUMBER
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.NUMBER:
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          if (!isNaN(parseInt(value))) return value;
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
            .UNDEFINED_RESPONSE;
        //TIME - DATE - DATETIME
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.TIME:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATE:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATETIME:
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return formatDate(value, profile);
        //VOID - SIGNERCANVAS
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.SIGNER_CANVAS:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.VOID:
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_RESPONSE;
        //COORDS
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.COORDS:
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
              .UNDEFINED_RESPONSE;
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          return value;
        //LIST
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.LIST:
          return value;
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.FILE:
          return value;
        default:
          return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES
            .UNDEFINED_RESPONSE;
      }
    }
    //Active Input Collector
    else {
      switch (typeKey) {
        //BOOLEAN
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.BOOLEAN:
          return value === "true" || value === true;
        //STRING
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.STRING:
          if (value === COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_RESPONSE;
          if (!value)
            return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_RESPONSE;
          return value;
        //NUMBER
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.NUMBER:
          return !isNaN(parseInt(value)) ? value : undefined;
        //TIME - DATE - DATETIME
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.TIME:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATE:
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.DATETIME:
          return value && moment(value).isValid() ? moment(value) : null;
        //LIST
        case COLLECTOR_KEYWORDS.COLLECTORS.TYPES.LIST:
          return value;
      }
    }
  }
  //Get delete collector from collector values
  static deleteCollectorFromCollectorValues(collectorValues, collectorProps) {
    return this.checkArray(collectorValues).filter((collector) =>
      CheckUtils.excludeCollectorInCollectorValues(collector, collectorProps)
    );
  }
  //Get avatar formatted
  static formatAvatars(data) {
    if (!Array.isArray(data)) return [];
    return data.map((otd) => ({
      id: otd.id,
      name: otd.creatorName,
      src: otd.creatorProfilePhoto,
      docId: otd.id,
    }));
  }
  //Get selected avatar id
  static getAvatarIdFromSelectedTransactionDoc(transactionDocs, selectedDocId) {
    const tDoc = this.checkArray(transactionDocs).find(
      (t) => t.id === selectedDocId
    );
    return tDoc?.id;
  }
  //Get available reports
  static getAvailableReports(selectedDocId, transactionDocs) {
    const tDoc = this.checkArray(transactionDocs).find(
      (t) => t.id === selectedDocId
    );
    return Immutable.List(tDoc?.availableReports).toJS() ?? [];
  }
  //Get url from available report
  static getUrlFromAvailableReport(serviceTaskId, availableReports) {
    return (
      this.checkArray(availableReports).find(
        (serviceTask) => serviceTask.id === serviceTaskId
      ) ?? {}
    );
  }
  //Get URI from Collector Ecosystem
  static getURI({ orderId, docId, state, workflow, context }) {
    switch (context) {
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .GET_AUDITED_TRANSACTION_DOCS:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!orderId) return undefined;
          return `/order_transaction_docs/getTotalAuditedOTD/${orderId}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .GET_SAVED_COLLECTOR_VALUES_BY_TRANSACTION_DOC:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!docId) return undefined;
          return `/collectorManager/v1/collectorValues/${orderId}/${docId}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .GET_SAVED_COLLECTOR_VALUES_BY_ORDER:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!orderId) return undefined;
          return `/collectorManager/v1/collectorValues/${orderId}/${docId}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .UPDATE_STATE_AUDITED_TRANSACTION_DOC:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          if (!docId || !state) return undefined;
          return `/order_transaction_docs/updateAuditedOTDState/${docId}/${state}`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .SAVE_COLLECTOR_VALUES:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/saveAuditedOrderCollectorValues`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .AUTO_SYNC_COLLECTOR_VALUES:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/saveAuditedOrderCollectorValues`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT
        .COMPLETE_AUDITED_REPORT:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/completeAuditedOrder`;
        }
        break;
      case COLLECTOR_KEYWORDS.TRANSACTION_DOCS.URI_CONTEXT.GENERATE_REPORT:
        if (workflow === COLLECTOR_KEYWORDS.COLLECTORS.WORKFLOW.OTD) {
          return `/collector_values/generateReportFromAuditedOrder`;
        }
        break;
      default:
        return;
    }
  }
  //Get selected transaction doc
  static getSelectedDocFromTransactionDocs(transactionDocs, selectedDocId) {
    return transactionDocs.find((t) => t.id === selectedDocId);
  }

  //Get collector photo config
  static getCollectorPhotoConfig(order, profile) {
    return GlobalUtils.selectCurrentProps([
      ProjectUtils.getProjectPropsFromOrder(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        order
      ),
      ServiceUtils.getServicePropsFromOrder(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        order
      ),
      OrderUtils.getOrderPropsFromOrder(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        order
      ),
      UserUtils.getUserPropsFromProfile(
        ENV.DEPARTMENTS.PROPS.COLLECTOR_PHOTO.NAME,
        profile
      ),
    ]);
  }

  /**
   * DUPLICATION ELEMENT
   */

  //Get duplicate label
  static getDuplicateLabel(level) {
    return DUPLICATION.NEW_NAME_LABEL[level];
  }
  //Get duplicate label
  static getDeleteLabel(level) {
    return DUPLICATION.DELETE_NAME_LABEL[level];
  }
  //Get original element name
  static getOriginalElementName(elementName) {
    if (typeof elementName !== "string" || !elementName.includes("#")) return;
    return elementName.split("#")[0].trim();
  }
  //Get original element name
  static getCurrentSequencialNumber(elementName) {
    if (typeof elementName !== "string" || !elementName.includes("#")) return;
    const currentNumber = elementName.split("#")[1]?.trim();
    return Number(currentNumber);
  }
  //Check duplicated level
  static isDuplicatedLevel(elementName) {
    const levelNumber = this.getCurrentSequencialNumber(elementName);
    return !isNaN(levelNumber) && levelNumber > 1;
  }
  //Get last sequence
  static getLastSequence(items) {
    return items
      .filter((sequencialNumber) => !!sequencialNumber)
      .sort((a, b) => (a > b ? -1 : b > a ? 1 : 0))[0]; //Sort in desc mode
  }
  //Get last sequence number
  static getLastSequenceNumber(originalElementName, elements) {
    return this.getLastSequence(
      elements
        .filter(({ name }) => name?.includes(originalElementName))
        .map(({ name }) => this.getCurrentSequencialNumber(name))
    );
  }
  //Get last sequence group number
  static getLastSequenceGroupNumber(
    level,
    originalElementName,
    { docId, orderId, review },
    collectors
  ) {
    const levelName =
      level === DUPLICATION.LEVELS.GROUP ? "groupName" : "subgroupName";

    return this.getLastSequence(
      this.getGroupsFromGroupedCollectors(
        level,
        { docId, orderId, review },
        collectors
      )
        .filter((record) => record[levelName]?.includes(originalElementName))
        .map((record) => this.getCurrentSequencialNumber(record[levelName]))
    );
  }
  //Get current last element
  static getCurrentLastElement(
    level,
    elementName,
    elements,
    { docId, orderId, review } = {}
  ) {
    //Get original element name (without sequencial number)
    const originalElementName = this.getOriginalElementName(elementName);
    if (!originalElementName) return {};

    //Get last sequence number
    let lastSequenceNumber;
    if (level === DUPLICATION.LEVELS.REVIEW) {
      lastSequenceNumber = this.getLastSequenceNumber(
        originalElementName,
        elements
      );
    } else if (
      level === DUPLICATION.LEVELS.GROUP ||
      level === DUPLICATION.LEVELS.SUBGROUP
    ) {
      lastSequenceNumber = this.getLastSequenceGroupNumber(
        level,
        originalElementName,
        { docId, orderId, review },
        elements
      );
    } else if (level === DUPLICATION.LEVELS.PHOTO) {
      lastSequenceNumber = this.getLastSequenceNumber(
        originalElementName,
        elements
      );
    }
    if (!lastSequenceNumber) return {};

    return { originalElementName, lastSequenceNumber };
  }
  //Get duplicated element name
  static getNewDuplicatedElement(
    level,
    elementName,
    elements,
    { docId, orderId, review }
  ) {
    const { originalElementName, lastSequenceNumber } =
      this.getCurrentLastElement(level, elementName, elements, {
        docId,
        orderId,
        review,
      });
    if (!originalElementName || !lastSequenceNumber) return;

    const newElementId = lastSequenceNumber + 1;

    return {
      newElementId,
      newElementName: `${originalElementName} #${newElementId}`,
    };
  }

  //Get duplicated collectors by review duplication process
  static reviewDuplicateProcess = ({
    docId,
    orderId,
    element,
    collectorLayout,
  }) => {
    if (!element) return;

    const reviews = collectorLayout.filter(
      (r) =>
        r.serviceId === element.serviceId &&
        r.serviceTaskId === element.serviceTaskId
    );

    const review = reviews.find((r) => r.id === element.reviewId);
    if (!review) return;

    const newDuplicatedElement = this.getNewDuplicatedElement(
      DUPLICATION.LEVELS.REVIEW,
      review.name,
      reviews,
      { docId, orderId, review }
    );
    if (!newDuplicatedElement) return;

    const { newElementId, newElementName } = newDuplicatedElement;
    return {
      newElementName,
      newDuplicatedElement: this.getDuplicatedReview(
        newElementId,
        newElementName,
        {
          docId,
          orderId,
          review,
          createdAt: element.createdAt,
        }
      ),
    };
  };

  //Get duplicated collectors by group duplication process
  static groupDuplicateProcess = ({
    docId,
    orderId,
    level,
    element,
    collectorLayout,
  }) => {
    if (!element) return;

    const review = collectorLayout.find(
      (r) =>
        r.serviceId === element.serviceId &&
        r.serviceTaskId === element.serviceTaskId &&
        r.id === element.reviewId
    );
    if (!review) return;

    const duplicateCollectors = review.collectors.filter(
      (collector) =>
        collector.groupId === element.groupId &&
        (level === DUPLICATION.LEVELS.GROUP ||
          collector.subgroupId === element.subgroupId)
    );
    const levelName =
      !!duplicateCollectors.length &&
      duplicateCollectors[0][
        level === DUPLICATION.LEVELS.GROUP ? "groupName" : "subgroupName"
      ];
    if (!levelName) return;

    const newDuplicatedElement = this.getNewDuplicatedElement(
      level,
      levelName,
      level === DUPLICATION.LEVELS.GROUP
        ? review.collectors
        : review.collectors.filter(
            (collector) => collector.groupId === element.groupId
          ),
      { docId, orderId, review }
    );
    if (!newDuplicatedElement) return;

    const { newElementId, newElementName } = newDuplicatedElement;
    return {
      newElementName,
      newDuplicatedElement:
        level === DUPLICATION.LEVELS.GROUP
          ? this.getDuplicatedGroup(newElementId, newElementName, {
              docId,
              orderId,
              collectors: duplicateCollectors,
              review,
              createdAt: element.createdAt,
            })
          : this.getDuplicatedSubgroup(newElementId, newElementName, {
              docId,
              orderId,
              collectors: duplicateCollectors,
              review,
              createdAt: element.createdAt,
            }),
    };
  };

  //Get duplicated photo by photo duplication process
  static photoDuplicateProcess = ({
    docId,
    orderId,
    element,
    collectorLayout,
  }) => {
    if (!element) return;

    const review = collectorLayout.find(
      (r) =>
        r.serviceId === element.serviceId &&
        r.serviceTaskId === element.serviceTaskId &&
        r.id === element.reviewId
    );
    if (!review) return;

    const collector = review.collectors.find(
      (collector) =>
        collector.groupId === element.groupId &&
        collector.subgroupId === element.subgroupId &&
        collector.id === element.collectorId
    );
    if (!collector) return;

    const photo = collector.photos.find(
      (photo) => photo.id === element.photoId
    );
    if (!photo?.name) return;

    const newDuplicatedElement = this.getNewDuplicatedElement(
      DUPLICATION.LEVELS.PHOTO,
      photo.name,
      collector.photos,
      { docId, orderId, review }
    );
    if (!newDuplicatedElement) return;

    const { newElementId, newElementName } = newDuplicatedElement;
    return {
      newElementName,
      newDuplicatedElement: this.getDuplicatedPhoto(
        newElementId,
        newElementName,
        {
          docId,
          orderId,
          collector,
          photo,
          review,
          createdAt: element.createdAt,
        }
      ),
    };
  };

  //Run duplicate element process
  static runDuplicateElementProcess = ({
    docId,
    orderId,
    level,
    element,
    collectorLayout,
  }) => {
    const duplicateProcessByLevel = {
      [DUPLICATION.LEVELS.REVIEW]: (payload) =>
        this.reviewDuplicateProcess(payload),
      [DUPLICATION.LEVELS.GROUP]: (payload) =>
        this.groupDuplicateProcess(payload),
      [DUPLICATION.LEVELS.SUBGROUP]: (payload) =>
        this.groupDuplicateProcess(payload),
      // [DUPLICATION.LEVELS.COLLECTOR]: (payload) =>
      //   this.collectorDuplicateProcess(payload),
      [DUPLICATION.LEVELS.PHOTO]: (payload) =>
        this.photoDuplicateProcess(payload),
    }[level];
    if (!duplicateProcessByLevel) return {};

    return (
      duplicateProcessByLevel({
        level,
        docId,
        orderId,
        element,
        collectorLayout,
      }) || {}
    );
  };
  //Get selected order from review
  static getSelectedOrderFromReviewManage(orderId, orders) {
    return orders.find((ord) => ord.order_id === orderId);
  }
  //Get collector layout by otd id
  static getCollectorLayoutByOtdId(order, docId, transactionDocs, templates) {
    if (!docId) return [];

    const otd = transactionDocs.find((o) => o.id === docId);
    if (!otd) return [];
    const collectorLayout = this.filterCollectorLayoutByTemplateId(
      otd.templateId,
      templates
    );

    //Filter Layout by service
    return this.filterCollectorLayoutByService(
      order.service_id,
      collectorLayout
    );
  }
  //Get default collector layout by type
  static getDefaultCollectorLayoutByType(order, templates) {
    const collectorLayout = this.filterCollectorLayoutByTemplateTypeId(
      order.order_target_id,
      templates
    );
    //Filter Layout by service
    return this.filterCollectorLayoutByService(
      order.service_id,
      collectorLayout
    );
  }

  //FILTERS
  //Filter collector values by docId || orderId
  static filterCollectorValues(collectorValues, docId, orderId) {
    return collectorValues.filter(
      (cv) => (docId && cv.docId === docId) || cv.auditOrderId === orderId
    );
  }

  //Filter collector layout by service
  static filterCollectorLayoutByService(serviceId, collectorLayout) {
    if (!serviceId) return [];
    return this.checkArray(collectorLayout).filter(
      (r) => !r.serviceId || Number(r.serviceId) === Number(serviceId)
    );
  }
  //Filter collector layout by template id
  static filterCollectorLayoutByTemplateId(templateId, templates) {
    if (!templateId) return [];
    const template = this.checkArray(templates).find(
      (t) => t.id === templateId
    );
    return this.checkArray(template?.data_structure_object);
  }
  //Filter collector layout by template type id
  static filterCollectorLayoutByTemplateTypeId(orderTargetId, templates) {
    const template = this.checkArray(templates).find(
      (t) => t.template_type_id === 4 && t.order_target_id === orderTargetId
    ); //4: Auditoria [Orden]
    return this.checkArray(template?.data_structure_object);
  }

  //SETTERS
  //Add collector value
  static addCollectorValue(collectorValues, value, collectorProps) {
    collectorValues.push({
      ...collectorProps,
      value,
      status: COLLECTOR_KEYWORDS.COLLECTORS.STATUS.LOADING,
      createdAt: new Date().toISOString(),
    });
  }
  //Add duplicated collector layout
  static addDuplicatedCollectorLayout(
    orderId,
    docId,
    collectorLayout,
    duplicatedCollectorLayout
  ) {
    //Add duplicated collectors to collectorLayout
    this.checkArray(duplicatedCollectorLayout)
      .filter(
        (duplicatedReview) =>
          (docId && duplicatedReview.docId === docId) ||
          duplicatedReview.orderId === orderId
      )
      .map((duplicatedReview) => {
        //Review doesn't exists in collectorLayout?
        const reviewIdx = collectorLayout.findIndex(
          (review) =>
            this.getDuplicatedReviewIdx({
              orderId,
              serviceId: review.serviceId,
              serviceTaskId: review.serviceTaskId,
              duplicatedReviewName: review.name,
            }) === duplicatedReview.reviewIdx
        );
        const review = collectorLayout[reviewIdx];
        if (!review) return collectorLayout.push(duplicatedReview);

        //Add duplicated collectors to collectorLayout?
        duplicatedReview.collectors.map((duplicatedCollector) => {
          //Group doesn't exists in collectorLayout?
          const group = review.collectors.find(
            (c) =>
              this.getDuplicatedGroupIdx({
                orderId,
                serviceId: review.serviceId,
                serviceTaskId: review.serviceTaskId,
                reviewId: review.name,
                duplicatedGroupName: c.groupName,
              }) === duplicatedCollector.groupIdx
          );
          if (!group) return review.collectors.push(duplicatedCollector);

          //Subgroup doesn't exists in collectorLayout?
          const subgroup = review.collectors.find(
            (c) =>
              this.getDuplicatedSubgroupIdx({
                orderId,
                serviceId: review.serviceId,
                serviceTaskId: review.serviceTaskId,
                reviewId: review.name,
                groupId: c.groupName,
                duplicatedSubgroupName: c.subgroupName,
              }) === duplicatedCollector.subgroupIdx
          );
          if (!subgroup) return review.collectors.push(duplicatedCollector);

          //Collector doesn't exists in collectorLayout?
          const collectorIdx = review.collectors.findIndex(
            (c) =>
              this.getDuplicatedCollectorIdx({
                orderId,
                serviceId: review.serviceId,
                serviceTaskId: review.serviceTaskId,
                reviewId: review.name,
                groupId: c.groupName,
                subgroupId: c.subgroupName,
                duplicatedCollectorName: c.name,
              }) === duplicatedCollector.collectorIdx
          );
          const collector = review.collectors[collectorIdx];
          if (!collector) return review.collectors.push(duplicatedCollector);

          duplicatedCollector.photos.map((duplicatedPhoto) => {
            //Photo doesn't exists in collectorLayout?
            const photo = collector.photos.find(
              (p) =>
                UploadResourceUtils.getDuplicatedPhotoIdx({
                  orderId,
                  serviceId: review.serviceId,
                  serviceTaskId: review.serviceTaskId,
                  reviewId: review.id,
                  groupId: collector.groupName,
                  subgroupId: collector.subgroupName,
                  collectorId: collector.name,
                  duplicatedPhotoName: p.name,
                }) === duplicatedPhoto.photoIdx
            );
            if (!photo) return collector.photos.push(duplicatedPhoto);
          });

          review.collectors[collectorIdx] = collector;
        });
        collectorLayout[reviewIdx] = review;
      });

    return collectorLayout;
  }

  static getNonRemovableDuplicatedCollectorLayout(
    level,
    deletedAt,
    {
      docId,
      orderId,
      serviceId,
      serviceTaskId,
      duplicatedReviewName,
      duplicatedGroupName,
      duplicatedSubgroupName,
      duplicatedCollectorName,
      duplicatedPhotoName,
      duplicatedCollectorLayout,
    }
  ) {
    //Filter and remove collectors that now exists in collectorLayout
    return this.checkArray(duplicatedCollectorLayout).reduce(
      (acc, duplicatedReview) => {
        //Return if the review belongs to another order
        if (
          (!!docId &&
            !!duplicatedReview.docId &&
            duplicatedReview.docId !== docId) ||
          duplicatedReview.orderId !== orderId
        ) {
          acc.push(duplicatedReview);
          return acc;
        }

        //Return if the review belongs to another review
        if (level === DUPLICATION.LEVELS.REVIEW) {
          if (!this.isDuplicatedLevel(duplicatedReviewName)) {
            acc.push(duplicatedReview);
            return acc;
          }
          const review =
            duplicatedReview.reviewIdx ===
              this.getDuplicatedReviewIdx({
                orderId,
                serviceId,
                serviceTaskId,
                duplicatedReviewName,
              }) &&
            new Date(deletedAt) - new Date(duplicatedReview.createdAt) > 0; // Si <duplicatedReview.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedReview.createdAt> y no se borra
          if (!review) acc.push(duplicatedReview);
          return acc;
        }

        //Return collector doesn't exists in collectorLayout
        duplicatedReview.collectors = duplicatedReview.collectors.reduce(
          (acc, duplicatedCollector) => {
            //Return if the group belongs to another group
            if (level === DUPLICATION.LEVELS.GROUP) {
              if (!this.isDuplicatedLevel(duplicatedGroupName)) {
                acc.push(duplicatedCollector);
                return acc;
              }
              const group =
                duplicatedCollector.groupIdx ===
                  this.getDuplicatedGroupIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    duplicatedGroupName,
                  }) &&
                new Date(deletedAt) - new Date(duplicatedCollector.createdAt) >
                  0; // Si <duplicatedCollector.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedCollector.createdAt> y no se borra
              if (!group) acc.push(duplicatedCollector);
              return acc;
            }
            //Return if the subgroup belongs to another subgroup
            if (level === DUPLICATION.LEVELS.SUBGROUP) {
              if (!this.isDuplicatedLevel(duplicatedSubgroupName)) {
                acc.push(duplicatedCollector);
                return acc;
              }
              const subgroup =
                duplicatedCollector.subgroupIdx ===
                  this.getDuplicatedSubgroupIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    groupId: duplicatedGroupName,
                    duplicatedSubgroupName,
                  }) &&
                new Date(deletedAt) - new Date(duplicatedCollector.createdAt) >
                  0; // Si <duplicatedCollector.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedCollector.createdAt> y no se borra;
              if (!subgroup) acc.push(duplicatedCollector);
              return acc;
            }

            //Return if the collector belongs to another collector
            if (level === DUPLICATION.LEVELS.COLLECTOR) {
              if (!this.isDuplicatedLevel(duplicatedCollectorName)) {
                acc.push(duplicatedCollector);
                return acc;
              }
              const collector =
                duplicatedCollector.collectorIdx ===
                  this.getDuplicatedCollectorIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    groupId: duplicatedGroupName,
                    subgroupId: duplicatedSubgroupName,
                    duplicatedCollectorName,
                  }) &&
                new Date(deletedAt) - new Date(duplicatedCollector.createdAt) >
                  0; // Si <duplicatedCollector.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedCollector.createdAt> y no se borra
              if (!collector) acc.push(duplicatedCollector);
              return acc;
            }

            //Return if the photo belongs to another photo
            if (level === DUPLICATION.LEVELS.PHOTO) {
              duplicatedCollector.photos = duplicatedCollector.photos.filter(
                (duplicatedPhoto) => {
                  if (!this.isDuplicatedLevel(duplicatedPhotoName)) return true;
                  const photo =
                    duplicatedPhoto.photoIdx ===
                      UploadResourceUtils.getDuplicatedPhotoIdx({
                        orderId,
                        serviceId,
                        serviceTaskId,
                        reviewId: duplicatedReviewName,
                        groupId: duplicatedGroupName,
                        subgroupId: duplicatedSubgroupName,
                        collectorId: duplicatedCollectorName,
                        duplicatedPhotoName,
                      }) &&
                    new Date(deletedAt) - new Date(duplicatedPhoto.createdAt) >
                      0; // Si <duplicatedPhoto.createdAt> se creó después de ser borrada en otro dispositivo, se conserva <duplicatedPhoto.createdAt> y no se borra;
                  return !photo;
                }
              );
              acc.push(duplicatedCollector);
            }

            return acc;
          },
          []
        );

        if (duplicatedReview.collectors[0]) acc.push(duplicatedReview);
        return acc;
      },
      []
    );
  }

  //Format to update duplicated collector layout ids
  static formatToUpdateDuplicatedCollectorLayoutIds(
    orderId,
    docId,
    duplicatedCollectorLayout
  ) {
    const isSyncCollector = (c) => !c.layoutId;
    const getSyncPhotos = (photos) =>
      photos.filter((photo) => !photo.layoutPhotoId);

    return this.checkArray(duplicatedCollectorLayout)
      .filter(
        (duplicatedReview) =>
          (docId && duplicatedReview.docId === docId) ||
          duplicatedReview.orderId === orderId
      )
      .reduce((acc, duplicatedReview) => {
        duplicatedReview.collectors.map((c) => {
          const syncPhotos = getSyncPhotos(c.photos);
          if (!isSyncCollector(c) && !syncPhotos.length) return acc;

          const syncCollector = {
            layoutId: c.layoutId,
            collectorId: c.id,
            collectorName: c.name,
            subgroupId: c.subgroupId,
            subgroupName: c.subgroupName,
            groupId: c.groupId,
            groupName: c.groupName,
            reviewId: c.reviewId,
            reviewName: duplicatedReview.name,
            serviceTaskId: duplicatedReview.serviceTaskId,
            serviceId: duplicatedReview.serviceId,
            templateId: c.templateId,
            required: c.required,
            sort: c.sort,
          };
          if (!syncPhotos.length) acc.push(syncCollector);
          else
            syncPhotos.forEach((photo) => {
              acc.push({
                ...syncCollector,
                layoutPhotoId: photo.layoutPhotoId,
                photoId: photo.id,
                photoName: photo.name,
                required: photo.required,
                sort: photo.sort,
                rliSort: syncCollector.sort,
              });
            });
        });
        return acc;
      }, []);
  }
  static updateOfflineDuplicatedCollectorLayoutIds(
    orderId,
    duplicatedCollectorLayout,
    duplicatedCollectorLayoutIds
  ) {
    // 1: Obtener únicamente todos los que van a ser actualizados
    // 2: Actualizarlos
    // 3: Y pasárselos al método combineDuplicatedCollectorLayout
    return duplicatedCollectorLayoutIds.reduce(
      (
        updateReviews,
        {
          serviceId,
          serviceTaskId,
          reviewId,
          reviewName: duplicatedReviewName,
          groupId,
          groupName: duplicatedGroupName,
          subgroupId,
          subgroupName: duplicatedSubgroupName,
          collectorId,
          collectorName: duplicatedCollectorName,
          photoId,
          photoName: duplicatedPhotoName,
          layoutId,
          layoutPhotoId,
        }
      ) => {
        const duplicatedReview = duplicatedCollectorLayout.find(
          ({ reviewIdx }) =>
            this.getDuplicatedReviewIdx({
              orderId,
              serviceId,
              serviceTaskId,
              duplicatedReviewName,
            }) === reviewIdx
        );
        if (!duplicatedReview) return updateReviews;

        duplicatedReview.id = reviewId;

        const duplicatedCollector = duplicatedReview.collectors.find(
          ({ groupIdx, subgroupIdx, collectorIdx }) =>
            this.getDuplicatedGroupIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              duplicatedGroupName,
            }) === groupIdx &&
            this.getDuplicatedSubgroupIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              groupId: duplicatedGroupName,
              duplicatedSubgroupName,
            }) === subgroupIdx &&
            this.getDuplicatedCollectorIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              groupId: duplicatedGroupName,
              subgroupId: duplicatedSubgroupName,
              duplicatedCollectorName,
            }) === collectorIdx
        );
        if (!duplicatedCollector) return updateReviews;

        if (groupId) duplicatedCollector.groupId = groupId;
        if (subgroupId) duplicatedCollector.subgroupId = subgroupId;
        duplicatedCollector.id = collectorId;
        duplicatedCollector.reviewId = reviewId;
        duplicatedCollector.layoutId = layoutId;

        const duplicatedPhoto = duplicatedCollector.photos.find(
          ({ photoIdx }) =>
            UploadResourceUtils.getDuplicatedPhotoIdx({
              orderId,
              serviceId,
              serviceTaskId,
              reviewId: duplicatedReviewName,
              groupId: duplicatedGroupName,
              subgroupId: duplicatedSubgroupName,
              collectorId: duplicatedCollectorName,
              duplicatedPhotoName,
            }) === photoIdx
        );

        if (duplicatedPhoto) {
          duplicatedPhoto.id = photoId;
          duplicatedPhoto.layoutPhotoId = layoutPhotoId;
        }

        const updateReviewIdx = updateReviews.findIndex(
          ({ reviewIdx }) =>
            this.getDuplicatedReviewIdx({
              orderId,
              serviceId,
              serviceTaskId,
              duplicatedReviewName,
            }) === reviewIdx
        );

        if (updateReviewIdx === -1)
          updateReviews.push({
            ...duplicatedReview,
            collectors: [duplicatedCollector],
          });
        else {
          const updateCollectorIdx = updateReviews[
            updateReviewIdx
          ].collectors.findIndex(
            ({ groupIdx, subgroupIdx, collectorIdx }) =>
              this.getDuplicatedGroupIdx({
                orderId,
                serviceId,
                serviceTaskId,
                reviewId: duplicatedReviewName,
                duplicatedGroupName,
              }) === groupIdx &&
              this.getDuplicatedSubgroupIdx({
                orderId,
                serviceId,
                serviceTaskId,
                reviewId: duplicatedReviewName,
                groupId: duplicatedGroupName,
                duplicatedSubgroupName,
              }) === subgroupIdx &&
              this.getDuplicatedCollectorIdx({
                orderId,
                serviceId,
                serviceTaskId,
                reviewId: duplicatedReviewName,
                groupId: duplicatedGroupName,
                subgroupId: duplicatedSubgroupName,
                duplicatedCollectorName,
              }) === collectorIdx
          );

          if (updateCollectorIdx === -1)
            updateReviews[updateReviewIdx].collectors.push({
              ...duplicatedCollector,
              photos: duplicatedPhoto ? [duplicatedPhoto] : [],
            });
          else {
            if (duplicatedPhoto) {
              const updatePhotoIdx = updateReviews[updateReviewIdx].collectors[
                updateCollectorIdx
              ].photos.findIndex(
                ({ photoIdx }) =>
                  UploadResourceUtils.getDuplicatedPhotoIdx({
                    orderId,
                    serviceId,
                    serviceTaskId,
                    reviewId: duplicatedReviewName,
                    groupId: duplicatedGroupName,
                    subgroupId: duplicatedSubgroupName,
                    collectorId: duplicatedCollectorName,
                    duplicatedPhotoName,
                  }) === photoIdx
              );

              if (updatePhotoIdx === -1)
                updateReviews[updateReviewIdx].collectors[
                  updateCollectorIdx
                ].photos.push(duplicatedPhoto);
              else
                updateReviews[updateReviewIdx].collectors[
                  updateCollectorIdx
                ].photos[updatePhotoIdx] = duplicatedPhoto;
            }
          }
        }
        return updateReviews;
      },
      []
    );
  }

  //Update offline duplicated collector layout ids
  static async updateOfflineDuplicatedCollectorLayout(
    docId, //TODO: Verificar si debo utilizar el docId en el Idx hasheado/generado
    orderId,
    duplicatedCollectorLayoutIds
  ) {
    return this.reloadOfflineDuplicatedCollectorLayout().then(
      (duplicatedCollectorLayout) => {
        const _duplicatedCollectorLayout =
          this.updateOfflineDuplicatedCollectorLayoutIds(
            orderId,
            duplicatedCollectorLayout,
            duplicatedCollectorLayoutIds
          ).reduce((acc, newDuplicatedElement) => {
            acc = this.combineDuplicatedCollectorLayout(
              "create",
              duplicatedCollectorLayout,
              {
                newDuplicatedElement,
              }
            );
            return acc;
          }, duplicatedCollectorLayout);
        this.mutateOfflineDuplicatedCollectorLayout(_duplicatedCollectorLayout);
        return _duplicatedCollectorLayout;
      }
    );
  }

  static incrementCollectorSort(incrementedSortSequence, collectors) {
    return collectors.map((c) => {
      if (c.sort) c.sort = c.sort + incrementedSortSequence;
      return c;
    });
  }

  //Update collector value
  static updateCollectorValue(
    collectorValues,
    value,
    savedCollectorIdx,
    savedCollector
  ) {
    savedCollector.value = value;
    savedCollector.status = COLLECTOR_KEYWORDS.COLLECTORS.STATUS.LOADING;
    savedCollector.createdAt = new Date().toISOString();
    collectorValues[savedCollectorIdx] = savedCollector;
  }
  //Mutate Offline Collector Values V2
  static mutateOfflineCollectorValuesV2(collectorValues) {
    if (!Array.isArray(collectorValues)) return;
    idbHandler.setCollectorValues({ collectorValues });
  }
  //Mutate Offline autoFillCollector
  static mutateOfflineAutoFillCollector(autoFillCollector) {
    if (typeof autoFillCollector !== "object") return;
    idbHandler.setAutoFillCollector(autoFillCollector);
  }
  //Mutate Offline duplicated collector layout
  static mutateOfflineDuplicatedCollectorLayout(duplicatedCollectorLayout) {
    if (typeof duplicatedCollectorLayout !== "object") return;
    idbHandler.setCollectorDuplicatedLayout(duplicatedCollectorLayout);
  }
  //Change collector value
  static onCollectorValueChange(
    collectorValues,
    { value, collectorProps, savedCollectorIdx, savedCollector }
  ) {
    const _savedCollectorIdx =
      savedCollectorIdx !== undefined
        ? savedCollectorIdx
        : this.getCollectorIdxFromCollectorValues(
            collectorValues,
            collectorProps
          );
    const _savedCollector = !!savedCollector
      ? savedCollector
      : this.getCollectorValueFromIdx(collectorValues, _savedCollectorIdx);

    if (_savedCollector && value === undefined) {
      collectorValues = this.deleteCollectorFromCollectorValues(
        collectorValues,
        collectorProps
      );
    } else if (value || value === false || value === "" || value === 0) {
      if (_savedCollector) {
        this.updateCollectorValue(
          collectorValues,
          value,
          _savedCollectorIdx,
          _savedCollector
        );
      } else {
        this.addCollectorValue(collectorValues, value, collectorProps);
      }
    }
    return collectorValues;
  }
  static setDeleteCollectorValue() {
    return COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_VALUE;
  }
  //Set auto fill service task collectors
  static setAutoFillServiceTaskCollectors({
    order,
    collectorLayout,
    collectorValues,
    serviceTaskId,
    process,
    active,
  }) {
    const autoFillProcess = ({ collector, savedCollector, autoFillValue }) => {
      let value = autoFillValue;
      if (active) {
        //Only replace nullish collector values
        if (
          savedCollector?.value !== undefined &&
          savedCollector?.value !==
            COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.EMPTY_VALUE
        ) {
          return { mustReturn: true };
        }
      } else {
        //Only replace na collector values
        if (savedCollector?.value !== autoFillValue) {
          return { mustReturn: true };
        }
        value = this.setDeleteCollectorValue();
      }

      return { value };
    };

    //Select AutoFill Value
    const autoFillValue = {
      [COLLECTOR_KEYWORDS.COLLECTORS.AUTO_FILL_PROCESS.NA]:
        COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.NA_VALUE,
      [COLLECTOR_KEYWORDS.COLLECTORS.AUTO_FILL_PROCESS.DUP]:
        COLLECTOR_KEYWORDS.COLLECTORS.DEFAULT_VALUES.UNDEFINED_VALUE,
    }[process];

    if (!autoFillValue) return collectorValues;

    return collectorLayout
      .filter((review) => review.serviceTaskId === serviceTaskId)
      .reduce((acc, review) => [...acc, ...review.collectors], [])
      .reduce((_collectorValues, collector) => {
        const collectorProps = this.getCollectorProps(order, collector);
        const savedCollectorIdx = this.getCollectorIdxFromCollectorValues(
          _collectorValues,
          collectorProps
        );
        const savedCollector = this.getCollectorValueFromIdx(
          _collectorValues,
          savedCollectorIdx
        );
        const { value, mustReturn } = autoFillProcess({
          collector,
          savedCollector,
          autoFillValue,
        });
        if (mustReturn) return _collectorValues;

        _collectorValues = this.onCollectorValueChange(_collectorValues, {
          value,
          collectorProps,
          savedCollectorIdx,
          savedCollector,
        });

        return _collectorValues;
      }, collectorValues);
  }
  //Delete duplicated collector values
  static deleteDuplicatedCollectorValues(
    level,
    { collectorId, subgroupId, groupId, reviewId, serviceTaskId },
    order,
    filteredCollectorLayout,
    collectorValues,
    uploadResource
  ) {
    const validation = (collector) => {
      return {
        [DUPLICATION.LEVELS.REVIEW]: collector.reviewId === reviewId,
        [DUPLICATION.LEVELS.GROUP]: collector.groupId === groupId,
        [DUPLICATION.LEVELS.SUBGROUP]: collector.subgroupId === subgroupId,
        [DUPLICATION.LEVELS.COLLECTOR]: collector.id === collectorId,
      }[level];
    };
    return filteredCollectorLayout
      .filter(
        (review) =>
          review.serviceTaskId === serviceTaskId && review.id === reviewId
      )
      .reduce((acc, review) => [...acc, ...review.collectors], []) //Get flatten collectors
      .filter(validation)
      .reduce((_collectorValues, collector) => {
        const collectorProps = this.getCollectorProps(order, collector);
        const value = this.setDeleteCollectorValue();
        _collectorValues = this.onCollectorValueChange(_collectorValues, {
          value,
          collectorProps,
        });
        collector.photos.map((photo) => {
          //FileProps
          const fileProps = UploadResourceUtils.getCollectorResourceFileProps(
            { order_id: order.order_id, docId: order.docId },
            collector,
            photo
          );
          uploadResource(UploadResourceUtils.addFileResource({ fileProps }));
        });
        return _collectorValues;
      }, collectorValues);
  }
  //Delete collector value
  static getToDeleteCollectorValue(order, collector, collectorValues) {
    const collectorProps = this.getCollectorProps(order, collector);
    const savedCollectorIdx = this.getCollectorIdxFromCollectorValues(
      collectorValues,
      collectorProps
    );
    const savedCollector = this.getCollectorValueFromIdx(
      collectorValues,
      savedCollectorIdx
    );
    const value = this.setDeleteCollectorValue();
    if (
      savedCollector?.value === undefined ||
      savedCollector?.value === null ||
      savedCollector?.value === value
    )
      return;
    return { value, collectorProps };
  }

  //Enter to collector ecosystem
  static enterToCollectorEcosystem({
    orderId,
    mutate1ObjectInCollector,
    updateCollectorRequiredValidation,
    forceReadOnlyCollector,
  }) {
    //Update Review Manage - orderId
    mutate1ObjectInCollector("reviewManage", {
      orderId,
      forceReadOnlyCollector,
    });
    //Update collector required validation
    updateCollectorRequiredValidation({
      highlight: {},
      state: COLLECTOR_KEYWORDS.REQUIRED_VALIDATION.STATE.WAITING,
    });
  }
  //Left from collector ecosystem
  static leftFromCollectorEcosystem(doReset) {
    UploadResourceUtils.removeSuccessResources();
    doReset();
  }

  //HANDLERS
  //Handle on click confirm send report
  static handleOnClickConfirmSendReport({
    profile,
    order,
    docId,
    filteredCollectorLayout,
    collectorValues,
    resources,
    updateCollectorRequiredValidation,
    mutate1ObjectInCollector,
    completeAuditedReport,
    sendToast,
  }) {
    //Collector photo config
    const collectorPhotoConfig = this.getCollectorPhotoConfig(order, profile);
    if (
      this.checkIsCollectorAndResourceRequiredDataPending({
        isActiveDataValidator: collectorPhotoConfig.required,
        validateData: {
          order: {
            order_id: order.order_id,
            docId,
          },
          filteredCollectorLayout,
          collectorValues,
          resources,
        },
        updateCollectorRequiredValidation,
        sendToast,
      })
    ) {
      mutate1ObjectInCollector("reviewManage", {
        showSendConfirmationModal: false,
        sendingReport: true,
      });
      completeAuditedReport(order.order_id);
    }
  }
  //Handle on set auto fill service task collectors
  static handleOnSetAutoFillServiceTaskCollectors = (
    order,
    docId,
    collectorLayout,
    _collectorValues,
    autoFillCollector,
    mutate1ObjectInCollector,
    saveCollectorValues
  ) => {
    let collectorValues = Immutable.List(_collectorValues).toJS();
    //Build new Auto Fill Collector
    const newAutoFillCollector = {
      ...autoFillCollector,
      na: {
        ...autoFillCollector.na,
        [autoFillCollector.combineOrderAndServiceTaskId]:
          autoFillCollector.isActive,
      },
    };
    //Get updated collector values
    collectorValues = this.setAutoFillServiceTaskCollectors({
      order: { order_id: order.order_id, docId },
      collectorLayout,
      collectorValues,
      serviceTaskId: newAutoFillCollector.serviceTaskId,
      process: COLLECTOR_KEYWORDS.COLLECTORS.AUTO_FILL_PROCESS.NA,
      active:
        newAutoFillCollector.na[
          newAutoFillCollector.combineOrderAndServiceTaskId
        ],
    });
    mutate1ObjectInCollector("reviewManage", {
      collectorValues,
      autoFillCollector: {
        ...newAutoFillCollector,
        isOpenConfirmation: false,
        serviceTaskId: undefined,
        combineOrderAndServiceTaskId: undefined,
        isActive: undefined,
      },
    });
    this.mutateOfflineCollectorValuesV2(collectorValues);
    this.mutateOfflineAutoFillCollector({
      na: {
        ...newAutoFillCollector.na,
      },
    });
    saveCollectorValues(
      this.getUnsuccessCollectors({
        collectors: collectorValues,
      })
    );
  };
  //Handle on cancel auto fill service task collectors
  static handleOnCancelAutoFillServiceTaskCollectors(
    autoFillCollector,
    mutate1ObjectInCollector
  ) {
    mutate1ObjectInCollector("reviewManage", {
      autoFillCollector: {
        ...autoFillCollector,
        isOpenConfirmation: false,
        serviceTaskId: undefined,
        combineOrderAndServiceTaskId: undefined,
        isActive: undefined,
      },
    });
  }
  static combineDuplicatedCollectorLayout(
    mode,
    duplicatedCollectorLayout,
    { level, newDuplicatedElement, deletedElement }
  ) {
    const _duplicatedCollectorLayout = Immutable.List(
      duplicatedCollectorLayout
    ).toJS();

    const createElement = () => {
      const sortElement = (a, b, key) => {
        if (!a || !b || !key) return 0;

        //A
        const originalAName = this.getOriginalElementName(a[key]);
        const sequencialANumber = this.getCurrentSequencialNumber(a[key]);
        //B
        const originalBName = this.getOriginalElementName(b[key]);
        const sequencialBNumber = this.getCurrentSequencialNumber(b[key]);

        if (
          !originalAName ||
          !sequencialANumber ||
          !originalBName ||
          !sequencialBNumber
        ) {
          return a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0;
        }

        const names = [originalAName, originalBName];
        names.sort();

        const getNameIdx = (originalName) =>
          names.findIndex((name) => name === originalName);

        let sortByName = 0;
        if (originalAName !== originalBName) {
          const originalANameIdx = getNameIdx(originalAName);
          const originalBNameIdx = getNameIdx(originalBName);

          sortByName =
            originalANameIdx < originalBNameIdx
              ? -1
              : originalANameIdx > originalBNameIdx
              ? 1
              : 0;
        }

        const sortBySequencial =
          sequencialANumber < sequencialBNumber
            ? -1
            : sequencialANumber > sequencialBNumber
            ? 1
            : 0;

        return sortByName !== 0 ? sortByName : sortBySequencial;
      };
      const matchReview = (_duplicatedReview) => {
        return (
          ((newDuplicatedElement.docId &&
            _duplicatedReview.docId === newDuplicatedElement.docId) ||
            _duplicatedReview.orderId === newDuplicatedElement.orderId) &&
          _duplicatedReview.reviewIdx === newDuplicatedElement.reviewIdx &&
          (level !== DUPLICATION.LEVELS.REVIEW ||
            new Date(_duplicatedReview.createdAt) -
              new Date(newDuplicatedElement.createdAt) >=
              0)
        );
      };
      const matchCollector = (
        newCollector,
        {
          id,
          collectorIdx,
          subgroupId,
          subgroupIdx,
          groupId,
          groupIdx,
          createdAt,
        }
      ) => {
        return (
          (groupId === newCollector.groupId ||
            groupIdx === newCollector.groupIdx) &&
          (subgroupId === newCollector.subgroupId ||
            subgroupIdx === newCollector.subgroupIdx) &&
          (id === newCollector.id ||
            collectorIdx === newCollector.collectorIdx) &&
          ((level !== DUPLICATION.LEVELS.COLLECTOR &&
            level !== DUPLICATION.LEVELS.SUBGROUP &&
            level !== DUPLICATION.LEVELS.GROUP) ||
            new Date(createdAt) - new Date(newCollector.createdAt) >= 0)
        );
      };
      const matchPhoto = (newPhoto, duplicatedPhoto) => {
        return (
          (duplicatedPhoto.id === newPhoto.id ||
            duplicatedPhoto.photoIdx === newPhoto.photoIdx) &&
          (level !== DUPLICATION.LEVELS.PHOTO ||
            new Date(duplicatedPhoto.createdAt) -
              new Date(newPhoto.createdAt) >=
              0)
        );
      };

      //Duplicate review
      const duplicatedReviewIdx =
        _duplicatedCollectorLayout.findIndex(matchReview);
      const duplicatedReview = _duplicatedCollectorLayout[duplicatedReviewIdx];

      if (!duplicatedReview) {
        _duplicatedCollectorLayout.push(newDuplicatedElement);
        return _duplicatedCollectorLayout;
      }

      //Duplicate subgroups, groups & collectors
      newDuplicatedElement.collectors.forEach((newCollector) => {
        const duplicatedCollectorIdx = duplicatedReview.collectors.findIndex(
          (duplicatedCollector) =>
            matchCollector(newCollector, duplicatedCollector)
        );
        const duplicatedCollector =
          duplicatedReview.collectors[duplicatedCollectorIdx];

        if (!duplicatedCollector) {
          duplicatedReview.collectors.push(newCollector);
          return;
        }

        //Duplicate photos
        newCollector.photos.forEach((newPhoto) => {
          const duplicatedPhoto = duplicatedCollector.photos.find(
            (duplicatedPhoto) => matchPhoto(newPhoto, duplicatedPhoto)
          );
          if (!duplicatedPhoto) duplicatedCollector.photos.push(newPhoto);
        });
        duplicatedCollector.photos.sort((a, b) => sortElement(a, b, "name"));

        //Update duplicated collecctor
        duplicatedReview.collectors[duplicatedCollectorIdx] =
          duplicatedCollector;
      });

      duplicatedReview.collectors.sort((a, b) => {
        const sortKey =
          level === DUPLICATION.LEVELS.SUBGROUP
            ? "subgroupName"
            : level === DUPLICATION.LEVELS.GROUP
            ? "groupName"
            : level === DUPLICATION.LEVELS.COLLECTOR && "name";
        return sortElement(a, b, sortKey);
      });

      //Update duplicated review
      _duplicatedCollectorLayout[duplicatedReviewIdx] = duplicatedReview;

      _duplicatedCollectorLayout.sort((a, b) => sortElement(a, b, "name"));
      return _duplicatedCollectorLayout;
    };
    const deleteElement = () => {
      const {
        docId,
        orderId,
        serviceId,
        serviceTaskId,
        reviewName,
        groupName,
        subgroupName,
        collectorName,
        photoName,
        createdAt,
      } = deletedElement;

      return this.getNonRemovableDuplicatedCollectorLayout(level, createdAt, {
        docId,
        orderId,
        serviceId,
        serviceTaskId,
        duplicatedReviewName: reviewName,
        duplicatedGroupName: groupName,
        duplicatedSubgroupName: subgroupName,
        duplicatedCollectorName: collectorName,
        duplicatedPhotoName: photoName,
        duplicatedCollectorLayout: _duplicatedCollectorLayout,
      });
    };

    const combineMode = {
      create: () => createElement(),
      delete: () => deleteElement(),
    }[mode];
    if (!combineMode) return _duplicatedCollectorLayout;
    return combineMode();
  }
  static async handleOnConfirmDuplicateElement({
    level,
    newDuplicatedElement,
    incrementedSortSequence,
    mutate1ObjectInCollector,
    setAutoSync,
  }) {
    //Increment sort
    if (incrementedSortSequence) {
      newDuplicatedElement.collectors = this.incrementCollectorSort(
        incrementedSortSequence,
        newDuplicatedElement.collectors
      );

      newDuplicatedElement.collectors =
        UploadResourceUtils.incrementCollectorPhotoSort(
          incrementedSortSequence,
          newDuplicatedElement.collectors
        );
    }

    return this.reloadOfflineDuplicatedCollectorLayout().then(
      (duplicatedCollectorLayout) => {
        const _duplicatedCollectorLayout =
          this.combineDuplicatedCollectorLayout(
            "create",
            duplicatedCollectorLayout,
            {
              level,
              newDuplicatedElement,
            }
          );
        this.mutateOfflineDuplicatedCollectorLayout(_duplicatedCollectorLayout);
        mutate1ObjectInCollector("reviewManage", {
          duplicatedCollectorLayout: _duplicatedCollectorLayout,
        });
        setAutoSync({ duplicatedLayoutIdsActive: true });
      }
    );
  }
  static async handleOnConfirmDeleteElement(
    level,
    deletedElement,
    mutate1ObjectInCollector
  ) {
    const {
      docId,
      orderId,
      serviceId,
      serviceTaskId,
      reviewName,
      groupName,
      subgroupName,
      collectorName,
      photoName,
      createdAt,
    } = deletedElement;

    return this.reloadOfflineDuplicatedCollectorLayout().then(
      (duplicatedCollectorLayout) => {
        const _duplicatedCollectorLayout =
          this.getNonRemovableDuplicatedCollectorLayout(level, createdAt, {
            docId,
            orderId,
            serviceId,
            serviceTaskId,
            duplicatedReviewName: reviewName,
            duplicatedGroupName: groupName,
            duplicatedSubgroupName: subgroupName,
            duplicatedCollectorName: collectorName,
            duplicatedPhotoName: photoName,
            duplicatedCollectorLayout,
          });
        this.mutateOfflineDuplicatedCollectorLayout(_duplicatedCollectorLayout);
        mutate1ObjectInCollector("reviewManage", {
          duplicatedCollectorLayout: _duplicatedCollectorLayout,
        });
      }
    );
  }
  static handleOnConfirmDeleteElementCollectorValues(
    level,
    deletedElement,
    filteredCollectorLayout,
    collectorValues,
    mutate1ObjectInCollector,
    saveCollectorValues,
    uploadResource
  ) {
    //Get updated collector values
    collectorValues = this.deleteDuplicatedCollectorValues(
      level,
      deletedElement,
      { docId: deletedElement.docId, order_id: deletedElement.orderId },
      filteredCollectorLayout,
      collectorValues,
      uploadResource
    );

    //Update state
    mutate1ObjectInCollector("reviewManage", { collectorValues });
    //Update offline storage
    this.mutateOfflineCollectorValuesV2(collectorValues);
    //Send to database
    saveCollectorValues(
      this.getUnsuccessCollectors({ collectors: collectorValues })
    );
  }
}
